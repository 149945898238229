import './Love.scss';

export const Love = () => {
    return (
        <main className="love">
            <section className="love__text">
                <h1>Привіт, мій гарненький цуцику!</h1>
                <p>Ця сторіночка - лиш ще один спосіб сказати, що я тебе дуже люблю!</p>
                <p>Мені подобається проводити з тобою час, ти гарно йдеш зі мною в ногу, ти сильна і вмієш досягати
                    свого!</p>
                <p>Ти дуже приваблива, сексуальна, впевнена в собі, вмієш гарно зваблювати та викликати збудження.</p>
                <p>З тобою хочеться радитись, ділитись переживаннями, думками та поглядами; в тебе є своя думка, якої я
                    дослухаюсь.</p>
                <p>Люблю твоє гаряче тіло, твій холодний розум та жагу до життя!</p>
                <p>Скоріше б тебе взяти на ручки та гарно няшкати!</p>
            </section>
            <section className="love__images">
                <img src="/assets/1.jpg" alt="Beauty #1"/>
                <img src="/assets/2.jpg" alt="Beauty #2"/>
                <img src="/assets/3.jpg" alt="Beauty #3"/>
                <img src="/assets/4.jpg" alt="Beauty #4"/>
                <img src="/assets/5.jpg" alt="Beauty #5"/>
                <img src="/assets/6.jpg" alt="Beauty #6"/>
                <img src="/assets/7.jpg" alt="Beauty #7"/>
                <img src="/assets/10.jpg" alt="Beauty #10"/>
                <img src="/assets/11.jpg" alt="Beauty #11"/>
                <img src="/assets/12.jpg" alt="Beauty #12"/>
                <img src="/assets/13.jpg" alt="Beauty #13"/>
                <img src="/assets/14.jpg" alt="Beauty #14"/>
                <img src="/assets/15.jpg" alt="Beauty #15"/>
                <img src="/assets/16.jpg" alt="Beauty #16"/>
                <img src="/assets/17.jpg" alt="Beauty #17"/>
                <img src="/assets/18.jpg" alt="Beauty #18"/>
                <img src="/assets/19.jpg" alt="Beauty #19"/>
                <img src="/assets/20.jpg" alt="Beauty #20"/>
                <img src="/assets/21.jpg" alt="Beauty #21"/>
                <img src="/assets/22.jpg" alt="Beauty #22"/>
            </section>
        </main>
    )
}