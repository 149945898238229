import logo from './logo.svg';
import './App.scss';

function App() {
    return (
        <div className="App">
            <main>
                <section>
                    <h2>Структурні елементи (будівельні блоки ангуляра)</h2>
                    <p>
                        Ангуляр складається з
                        <ul>
                            <li><b>модулів</b> (в тому числі lazy loaded через роутинг)</li>
                            <li><b>компонентів</b> (в тому числі standalone з 14 версії)</li>
                            <li><b>директив</b> (структурних та темплейтних)</li>
                            <li><b>пайпів</b> (pure + impure)</li>
                            <li><b>сервісів</b> (в тому числі singleton сервісів, запровайджених через providedIn: root,
                                але які
                                дублюються в lazy-loaded модулях)
                            </li>
                            <li>а також додатково: <b>провайдерів</b> (useValue, useClass,
                                useFactory), <b>гуардів</b>, <b>інтерсепторів</b>
                            </li>
                        </ul>
                    </p>

                </section>

                <section>
                    <h2>Change Detection</h2>
                    <p>
                        Реалізований за допомогою <b>zone.js</b>, яка патчить усі <u>асинхронні операції</u> (проміси)
                        та <u>евент
                        хендлери </u>, а також <u>setTimeout/setInterval</u> щоб запускати change detection check з
                        рута.
                    </p>
                    <p> Є дві стратегії - <b>Default</b> та <b>OnPush</b>.</p>
                    <p>
                        <b>OnPush</b> стратегія запускає перевірку change detection у випадках
                        коли: <u>змінились @Input</u>, <u>відбувся user event </u> (click, тощо) в самому компоненті,
                        або його дочірніх
                        компонентах (ось пруф https://angular.io/guide/change-detection-skipping-subtrees#using-onpush)
                        , або за викликом <u>this.changeDetectionRef.detectChanges()</u>;
                    </p>


                </section>

                <section style={{gridRow: 'span 2'}}>
                    <h2>RxJs</h2>
                    <p>
                        базується на <b>Observable</b> - <u>lazy push collection of multiple values</u>. </p>
                    <p>
                        Lazy push collection означає
                        що:
                        <ul>
                            <li><b>lazy</b> = виконується лише по першій підписці</li>
                            <li><b>push collection</b> = означає що той, хто відправляє значення, сам визначає час і порядок їх
                                відправки. Приклади push collection: Promise, Observable. Приклади pull collections -
                                звичайні
                                функції (ти ініціюєш їх коли викликаєш функції), генератори (ти ініціюєш генератор коли
                                викликаєш generator.next()).
                            </li>
                            <li><b>of multiple values</b> = означає, що на відміну від проміса, результатів може бути більше 1.

                            </li>
                        </ul>
                    </p>

                    <p>
                        Observable бувають
                        <ul>
                            <li>                      <b>холодні</b> (ті, що починають виконання під час першої підписки і коли
                                кількість
                                підписників сягає 0, вони знищуються і при наступній підписці починають виконання
                                заново) </li>
                            <li>
                                <b>гарячі</b> (ті, що не знищуються після відписки останнього підписника).

                            </li>
                        </ul>
                    </p>

                    <p> <b>Subject</b> - спеціальний вид Observable, який дозволяє передавати значення усім підписникам ззовні
                        через next(). <i>Звичайний Subject - <b>холодний</b></i>. Існують ще <u>ReplaySubject</u>, <u>BehaviorSubject</u> та
                        <u>AsyncSubject</u>.
                    </p>

                </section>

                <section>
                    <h2>Typescript та класи</h2>
                    <p>
                        тут головне знати навіщо він є, що таке <b>дженеріки</b> (або ще їх називають "параметризація" ->
                        SomeType&lt;T&gt;), а також відмінність <b>unknown vs any</b>. <u>unknown</u> - у даної змінної тип невідомий
                        і ми
                        не можемо викликати абсолютно ніякі методи у неї, <u>any</u> - тип будь-який, відповідно можна
                        викликати будь-який метод.
                        </p>
                    <p>
                        Класи: ангуляр структурований класами з декораторами (@Component, @Service, це все - мета дані).
                        </p>
                    <p>
                        Є lifecycle hooks:
                        </p>
                    <p>
                        <b>constructor -> NgOnChanges -> NgOnInit -> NgDoCheck -> NgAfterViewInit -> NgAfterViewChecked ->
                        NgOnDestroy.</b>
                        </p>
                    <p>
                        Також, є ще лайфсайкл хуки для компонентів, які передані в даний компонент через content
                        projection:
                        constructor -> NgOnChanges -> NgOnInit -> NgDoCheck -> <u>NgAfterContentInit </u> ->
                        <u>NgAfterContentChecked</u> -> NgAfterViewInit -> NgAfterViewChecked -> NgOnDestroy
                    </p>
                </section>

                <section>
                    <h2>Cервіси та dependency inversion (+ injection)</h2>
                    <p>
                        сервіси несуть в собі бізнес-логіку.
                        </p>
                    <p> сервіси можуть бути <b>stateful</b> (зберігають якийсь стан або
                        кеш) або <b>stateless</b> (слугують чисто для виконання якихось операцій, не зберігають стан).
                        </p>
                    <p>
                        Мають
                        бути запровайджені в модулі або компоненті.
                        </p>
                    <p>
                        <b>Dependency inversion</b> каже, що конкретні <u>імплементації мають залежати від абстракції</u>, а не
                        навпаки. Це означає, що твої конкретні класи мають залежати від інтерфейсів, які передаються їм
                        як параметри, а не від конкретних класів.
                        </p>
                    <p>
                        Приклад: метод paint(car: CarInterface) може пофарбувати будь-який абстрактний автомобіль
                        інтерфейсу CarInterface, йому все одно на модель та марку. Той, хто буде викликати метод paint
                        сам має підставити вже конкретну реалізацію (конкретний автомобіль конкретної марки).
                        </p>
                    <p>
                        <b>Dependency Injection </b> це одна з реалізацій підходу <b>Dependency Inversion</b>, яка каже, що залежності
                        будуть передаватись в конструктор або як аргумент в методі. <i>Іншою реалізацією є, наприклад,
                        Service Locator. </i>
                    </p>
                </section>
            </main>
        </div>
    );
}

export default App;
